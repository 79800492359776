.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Current Weather Styling */
.main-icon,
.big-data h2 {
	font-size: 6em;
	border-radius: 50%;
	padding: 20px;
	margin: 20px 0 20px 0;
	display: inline-block;
	border-top: 20px;
}

.big-data h2 {
	border-left: 1px solid #2d3436;
	border-radius: 0;
}

.big-data h2,
.main-city,
.main-description {
	font-weight: 400;
}

.main-content {
	display: contents;
}

.main-description {
	font-size: 2.5em;
}

.main-city {
	font-size: 2em;
}

/* Color Styling Main Section */

.boiling {
	background: #ff7675;
}

.hot {
	background: #fab1a0;
}

.warm {
	background: #fce498;
}

.perfect {
	background: #6ac3a4;
}

.cool {
	background: #67c6bb;
}

.freezing {
	background: #80bcf8;
}

/* Color Styling Forecast Section */

.boiling-forecast {
	background: #d63031;
}

.hot-forecast {
	background: #e17055;
}

.warm-forecast {
	background: #fdcb6e;
}

.perfect-forecast {
	background: rgb( 228, 245, 251);
}

.cool-forecast {
	background: #22a39c;
}

.freezing-forecast {
	background: #278fdf;
}

/* Forecast Area Styling */

.horiz-menu {
	height: 150px;
	margin: 0 auto;
	/* margin-bottom: 20px; */
}

.forecast-cell {
	height: 150px;
	display: block !important;
	padding: 4 10px !important;
	text-align: center !important;
}

.forecast-area {
	width: 100%;
}

.forecast-cell > i {
	font-size: 3em;
	padding: 15px 0;
}

.forecast-cell > p {
	font-size: 0.7em;
	margin: 0;
}

.temp-text {
	font-size: 1.5em;
}

.forecast-cell h5 {
	display: block;
	margin: 0;
}

/* Menu Bar Styling */

.app-bar-logo {
	width: 70px;
	height: 70px;
	margin-left: -5px;
}

.menu {
	padding: 0 !important;
	font-family: 'Raleway', sans-serif !important;
	font-weight: 400;
}

/* Menu Font */

.location-button {
	min-width: 25% !important;
}

.cell-data {
	text-align: right !important;
}

.weather-details {
	font-family: 'Raleway', sans-serif !important;
}

.weather-details > tbody > tr > td {
	color: #2d3436 !important;
	font-size: 1.2em;
	letter-spacing: 0.1em;
}

.whole-details-area {
	padding: 20px 2% 80px;
	font-weight: 400;
}

.whole-details-area h4 {
	font-size: 2em;
	margin: 10px auto;
}

button.add-button {
	position: absolute;
	right: 0px;
}

svg.MuiSvgIcon-root-103 {
	color: black;
}

.inactiveInput input {
	color: lightgray !important;
}